<template>
  <div>
    <div class="right-menu shipping-right">

      <subHeader pageName="THEME BANNERS"/>
  
      <tutorialsAction />

      <div class="db_top pt-4">
        <div class="db-container">

          <div class="main-search-order">
            <div class="row search-order-product d-flex-middle">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-9">
                    <div class="addNewOrderBtn text-left" v-if="isDev()">
                      <button class="mr-2" @click.prevent="()=>{resetForm();form.isOpen = true; }" :disabled="tourIsActive">
                        <svg style="fill: #fff;" data-name="Layer 1" id="Layer_1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                          <title/>
                          <path d="M36.74,0V27.26H64v9.48H36.74V64H27.26V36.74H0V27.26H27.26V0Z" data-name="&lt;Compound Path&gt;" id="_Compound_Path_"/>
                        </svg>
                        ADD NEW SLIDE
                      </button>
                      <button class="mr-2" @click.prevent="()=>{resetForm();fieldForm.isOpen = true; }" :disabled="tourIsActive">
                        <svg style="fill: #fff;" data-name="Layer 1" id="Layer_1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                          <title/>
                          <path d="M36.74,0V27.26H64v9.48H36.74V64H27.26V36.74H0V27.26H27.26V0Z" data-name="&lt;Compound Path&gt;" id="_Compound_Path_"/>
                        </svg>
                        ADD NEW Field in ( {{ field }} )
                      </button>
                      <button class="mr-2" @click.prevent="setImageToDefaultHandle()" :disabled="tourIsActive">
                        <svg height="20px" id="Layer_1" style="enable-background:new 0 0 512 512;fill:#fff;" version="1.1" viewBox="0 0 512 512" width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g>
                            <path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                            <path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                          </g>
                        </svg>
                        Set Images to Default
                      </button>
                      <button @click.prevent="downloadBlockImagesHandle()" :disabled="tourIsActive">
                        <svg style="enable-background:new 0 0 24 24;fill:#fff;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="info"/>
                          <g id="icons">
                            <g id="save">
                              <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                              <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                            </g>
                          </g>
                        </svg>
                        Download Block Images
                      </button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="addNewOrderBtn text-right">
                      <button 
                        @click.prevent="$router.push('/theme-management/theme-customization')" 
                        id="edit-content-btn" 
                        :disabled="tourIsActive"
                      >
                        Edit Theme Content
                      </button>
                      <button @click.prevent="saveBlocks" id="save-btn" :disabled="tourIsActive">
                        SAVE
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="tabsMainSection">

            <!-- tabs button list block starts here -->
            <div class="tabsListSection">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation" v-for="(group, groupKey) in blocks" :key="group.id">
                  <button 
                    class="nav-link" 
                    :class="acitveTab == groupKey ? 'active' : ''" 
                    @click="selectTab(groupKey)" 
                    :id="`${group.id}`" 
                    data-toggle="pill" 
                    :data-target="`#${groupKey}`" 
                    type="button" 
                    role="tab" 
                    :aria-selected="acitveTab == group.name ? 'true' : ''" 
                    :aria-controls="`#${groupKey}`"
                    :disabled="tourIsActive"
                  >
                    {{ group.name }}
                  </button>
                  <a href="#." aria-hidden="true" id="page-options" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" v-if="isDev()">
                    <span class="arrowIconbox">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                        <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                      </svg>
                    </span>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="page-options" v-if="isDev()">
                    <button class="dropdown-item" @click.prevent="openEditSlideModal(group)">
                      Edit Page
                    </button>
                    <button class="dropdown-item" @click.prevent="removeSlideHandle(group)">
                      Delete Page
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <!-- tabs button list block ends here -->

            <!-- tabs content section block starts here -->
            <div class="tabsContentSection">
              <div class="tabsContent" id="content-blocks-section">
                <div class="tab-content" id="pills-tabContent">
                  <div 
                    class="tab-pane fade" 
                    :class="acitveTab == groupKey ? 'active show' : ''" 
                    :id="groupKey" role="tabpanel" 
                    :aria-labelledby="group.id" 
                    v-for="(group,groupKey) in blocks" 
                    :key="group.id"
                  >
                    <div class="switch-div" style="justify-content: center;margin-top:20px;" v-if="Object.keys(group.fields).length != 0">
                      <div>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" v-model="group.active" class="custom-control-input" :id="`customSwitch1-${group.id}`" :disabled="tourIsActive">
                          <label class="custom-control-label" :for="`customSwitch1-${group.id}`" style="color:#000;">{{ group.active ? 'Active' : 'Disabled'}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="tabMainBox" v-if="isDev()">
                      <div class="noFieldsWrap" v-if="Object.keys(group.fields).length == 0">
                        <p>No Fields Available In {{ field }}</p>
                      </div>
                      <div class="tabCardBox" id="content-card" v-for="(field, fieldKey) in group.fields" :key="field.id">
                        <button class="ctmCloseBtn" @click.prevent="handleDeleteField(groupKey, fieldKey)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                          </svg>
                        </button>
                        <div class="fieldBox">
                          <label for="fieldId">ID</label>
                          <input type="text" name="fieldId" id="fieldId" placeholder="ID" v-model="field.id" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="fieldName">Name</label>
                          <input type="text" name="fieldName" id="fieldName" placeholder="Name" v-model="field.name" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="fieldSlug">Slug</label>
                          <input type="text" name="fieldSlug" id="fieldSlug" placeholder="Slug" :value="fieldKey" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="fieldType">Type</label>
                          <input type="text" name="fieldType" id="fieldType" placeholder="Type" v-model="field.type" disabled />
                        </div>
                        <template v-if="field.type == 'text'">
                          <div class="fieldBox">
                            <label for="fieldValue">Value</label>
                            <input type="text" name="fieldValue" id="fieldValue" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="fieldColor">Color</label>
                            <input type="color" name="fieldColor" id="fieldColor" v-model="field.color" />
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image'">
                          <div class="fieldBox">
                            <label for="fieldAlt">Alt</label>
                            <input type="text" name="fieldAlt" id="fieldAlt" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="fieldLink">Link</label>
                            <input type="text" name="fieldLink" id="fieldLink" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :id="`blockDefaultImage-${field.id}`" :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, groupKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockDefaultImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/><path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :id="`blockImage-${field.id}`" :src="base_image_url + settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, groupKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                                      <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="linkFieldText">Text</label>
                            <input type="text" name="linkFieldText" id="linkFieldText" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldUrl">Link</label>
                            <input type="text" name="linkFieldUrl" id="linkFieldUrl" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldNewTab">New Tab</label>
                            <input type="checkbox" name="linkFieldNewTab" id="linkFieldNewTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldTextColor">Text Color</label>
                            <input type="color" name="linkFieldTextColor" id="linkFieldTextColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="tabMainBox" v-else>
                      <div class="noFieldsWrap" v-if="Object.keys(group.fields).length == 0">
                        <p>No Fields Available In {{ field }}</p>
                      </div>
                      <div class="tabCardBox" id="content-card" v-for="(field, fieldKey) in group.fields" :key="field.id">
                        <template v-if="field.type == 'text'">
                          <div class="fieldBox">
                            <label for="textFieldValue">Value</label>
                            <input type="text" name="textFieldValue" id="textFieldValue" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="textFieldColor">Color</label>
                            <input type="color" name="textFieldColor" id="textFieldColor" v-model="field.color" />
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image'">
                          <div class="fieldBox">
                            <label for="imageFieldAlt">Alt</label>
                            <input type="text" name="imageFieldAlt" id="imageFieldAlt" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="imageFieldLink">Link</label>
                            <input type="text" name="imageFieldLink" id="imageFieldLink" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,groupKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :src="base_image_url +settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,groupKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="linkFieldText">Text</label>
                            <input type="text" name="linkFieldText" id="linkFieldText" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldUrl">Link</label>
                            <input type="text" name="linkFieldUrl" id="linkFieldUrl" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldNewTab">New Tab</label>
                            <input type="checkbox" name="linkFieldNewTab" id="linkFieldNewTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkFieldTextColor">Text Color</label>
                            <input type="color" name="linkFieldTextColor" id="linkFieldTextColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- tabs content section block ends here -->

          </div>

        </div>
      </div>

    </div>

    <!-- add new slide modal starts here -->
    <div class="modalBg" :class="form.isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="form.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position:relative; top:15px; right:0; width:100%;">
          <ValidationObserver ref="addSlideObserver" >
            <form @submit.prevent="validate('add')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>ADD NEW SLIDE</h2>
                    </div>
                    <div class="row">
                      
                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="newSlideLabel">Label <span>*</span></label>
                            <input 
                              name="newSlideLabel" 
                              id="newSlideLabel"
                              v-model="form.label" 
                              autocomplete="off" 
                              placeholder="Label"
                              @input="slugHandle('add')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="newSlideSlug">Slug <span>*</span></label>
                            <input 
                              name="newSlideSlug" 
                              id="newSlideSlug" 
                              placeholder="Slug"
                              v-model="form.slug" 
                              @input="slugHandleSlugField('add')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="updateProductBtnBox categorySideBar">
                          <div class="updateCtmCheckBox">
                            <p>Status</p>
                            <div class="productStatusBox">
                              <p>HIDDEN</p>
                              <div class="actionSwitch">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  id="add-product-switch"
                                  v-model="form.active"
                                />
                                <span class="slider round"></span>
                              </label>
                              </div>
                              <p>VISIBLE</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn" >
                          <button type="submit" class="product-btn">SAVE NEW SLIDE</button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="form.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- add new slide modal ends here -->

    <!-- update slide modal starts here -->
    <div class="modalBg" :class="updateSlideForm.isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="updateSlideForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position:relative; top:15px; right:0; width:100%;">
          <ValidationObserver ref="updateSlideObserver">
            <form @submit.prevent="validate('update')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>UPDATE SLIDE</h2>
                    </div>
                    <div class="row">

                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="updateSlideLabel">Label <span>*</span></label>
                            <input 
                              name="updateSlideLabel" 
                              id="updateSlideLabel" 
                              v-model="updateSlideForm.label" 
                              autocomplete="off"
                              placeholder="Label" 
                              @input="slugHandle('update')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="updateSlideSlug">Slug <span>*</span></label>
                            <input 
                              name="updateSlideSlug" 
                              id="updateSlideSlug" 
                              placeholder="Slug" 
                              v-model="updateSlideForm.slug" 
                              @input="slugHandleSlugField('update')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="updateProductBtnBox categorySideBar">
                          <div class="updateCtmCheckBox">
                            <p>Status</p>
                            <div class="productStatusBox">
                              <p>HIDDEN</p>
                              <div class="actionSwitch">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  id="add-product-switch"
                                  v-model="updateSlideForm.active"
                                />
                                <span class="slider round"></span>
                              </label>
                              </div>
                              <p>VISIBLE</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn" >
                          <button type="submit" class="product-btn">SAVE SLIDE</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="updateSlideForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- update slide modal starts end -->

    <!-- add new field modal starts here -->
    <div class="modalBg" :class="fieldForm.isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="fieldForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position:relative; top:15px; right:0; width:100%;">
          <ValidationObserver ref="addFieldObserver" >
            <form @submit.prevent="validate('addField')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>ADD NEW FIELD IN ( {{ field }} )</h2>
                    </div>
                    <div class="row">

                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="fieldLabel">Label <span>*</span></label>
                            <input 
                              name="fieldLabel" 
                              id="fieldLabel" 
                              v-model="fieldForm.label" 
                              autocomplete="off"
                              placeholder="Label"  
                              @input="slugHandle('addField')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="newFieldSlug">Slug <span>*</span></label>
                            <input 
                              name="newFieldSlug" 
                              id="newFieldSlug" 
                              placeholder="Slug" 
                              v-model="fieldForm.slug" 
                              @input="slugHandleSlugField('addField')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="slug">Type <span>*</span></label>
                            <select v-model="fieldForm.type">
                              <option value="text">Text</option>
                              <option value="image">Image</option>
                              <option value="link">Link</option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>

                      <!-- if field type is 'text' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'text'">
                          <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="newTextFieldValue">Value <span>*</span></label>
                              <input 
                                name="newTextFieldValue" 
                                id="newTextFieldValue"
                                placeholder="Value"  
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'text'">
                          <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Color <span>*</span></label>
                              <input type="color" name="newTextFieldColor" id="newTextFieldColor" v-model="fieldForm.color"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>

                      <!-- if field type is 'text' ends here -->

                      <!-- if field type is 'image' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <ValidationProvider name="image" ref="imageProvider" rules="required|image" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Image <span>*</span></label>
                              <input 
                                type="file" 
                                accept="image/png, image/jpeg, image/webp" 
                                name="image" 
                                id="image" 
                                ref="fieldImageUploader"
                                @change="handleImageAddField"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <ValidationProvider name="alt" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="newImageFieldAlt">Alt <span>*</span></label>
                              <input 
                                name="newImageFieldAlt" 
                                id="newImageFieldAlt" 
                                placeholder="Alt"
                                v-model="fieldForm.alt"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <div class="formContentBox">
                            <label for="newImageFieldLink">Link <span></span></label>
                            <input 
                              name="newImageFieldLink" 
                              id="newImageFieldLink" 
                              placeholder="Link"
                              v-model="fieldForm.link"
                            />
                          </div>
                        </div>

                      <!-- if field type is 'image' ends here -->

                      <!-- if field type is 'link' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="newLinkFieldText">Text <span>*</span></label>
                              <input 
                                name="newLinkFieldText" 
                                id="newLinkFieldText" 
                                placeholder="Text"
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="newLinkFieldUrl">Link <span>*</span></label>
                              <input 
                                name="newLinkFieldUrl" 
                                id="newLinkFieldUrl" 
                                placeholder="Link"
                                v-model="fieldForm.link"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <div class="formContentBox">
                            <label for="">New Tab</label>
                            <input 
                              type="checkbox" 
                              name="newLinkFieldNewTab" 
                              id="newLinkFieldNewTab" 
                              v-model="fieldForm.newTab"
                            />
                          </div>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Text Color <span>*</span></label>
                              <input type="color" name="slug" id="slug" v-model="fieldForm.color"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>

                      <!-- if field type is 'link' ends here -->

                      <div class="col-md-12">
                        <div class="assign-btn" >
                          <loaderBtn class="product-btn" v-if="fieldForm.isLoading"/>
                          <button type="submit" v-else class="product-btn">SAVE NEW FIELD</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="fieldForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- add new field modal ends here -->

    <v-tour name="homeBannersTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
          
  </div>
</template>
  
<script>
import {MessageBox, Message, Loading } from 'element-ui'
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo() {
    return {
      title: 'Theme Management | Theme Banners | YeetCommerce',
    };
  },
  data() {
    return {
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      spaces: process.env.VUE_APP_SPACES,
      acitveTab: '',
      tempActiveTab:"",
      blocks: {},
      form:{
        label: '',
        slug: '',
        active: true,
        isOpen: false,
      },
      updateSlideForm:{
        label:'',
        slug:'',
        active: true,
        slideId:null,
        tempLabel:'',
        tempSlug:'',
        isOpen: false,
      },
      fieldForm: {
        isOpen: false,
        label: '',
        slug: '',
        type: 'text',
        value: '',
        alt: '',
        link: '',
        isLoading: false,
        default: true,
        newTab: false,
        color: '#000000'
      },
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //home banner blocks steps
        {
          target: '#pills-tab',
          content: `<strong>Slide Selection</strong><br>You have the option to choose a slide for which you wish to update the content.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#content-blocks-section',
          content: `<strong>Banner Block</strong><br>You have the option to modify the content of a banner block by utilizing the provided fields.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
        {
          target: '#edit-content-btn',
          content: `<strong>Edit Theme Content</strong><br>Theme content editing screen can be accessed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
        {
          target: '#save-btn',
          content: `<strong>Save Changes</strong><br>Changes can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
      ],
      tourIsActive:false
    }
  },
  components: {
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
    }),
    field(){

      if(this.acitveTab != '' && this.acitveTab != '0' && this.blocks[this.acitveTab] != undefined && this.blocks[this.acitveTab].name ){

        return this.blocks[this.acitveTab].name 

      }else{
        
        return ''

      }

    },
  },
  watch:{
    'form.name': {
      handler: function(value){

        this.form.slug = value.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

      }
    },
    'settings.banner_blocks': {
      handler: function(value){

        let obj = value ? JSON.parse(value) : {};

        if(Object.keys(obj).length > 0){

          if(this.tempActiveTab == ""){

            this.acitveTab = Object.keys(obj)[0];

          }else{

            this.acitveTab = this.tempActiveTab

          }

        }

        this.blocks = obj;
        
      },
      deep: true
    },
  },
  methods: {
    selectTab(selectValue){

      if(!this.tourIsActive){

        this.acitveTab = selectValue;

      }

    },
    slugHandle(form){

      if(form == "add"){

        let slug = this.form.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.form.slug = slug;

      }else if(form == "update"){

        let slug = this.updateSlideForm.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.updateSlideForm.slug = slug;

      }else{

        let slug = this.fieldForm.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.fieldForm.slug = slug;

      }

    },
    slugHandleSlugField(form){

      if(form == "add"){

        let slug = this.form.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.form.slug = slug;

      }else if(form == "update"){

        let slug = this.updateSlideForm.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.updateSlideForm.slug = slug;

      }else if(form == 'addField'){

        let slug = this.fieldForm.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');
  
        this.fieldForm.slug = slug;

      }

    },
    async validate(action){

      if(action == "add"){

        this.$refs.addSlideObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.addSlideObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.addSlideObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{
          
            if(this.blocks[this.form.slug] != undefined){

              this.$message({
                type: "error",
                showClose: true,
                message: "The entered slug has already been used.",
              });

              return 

            }

            let obj = {
              id: uuidv4(),
              name: this.form.label,
              active: this.form.active,
              fields: {}
            }

            this.blocks[this.form.slug] = obj;
            this.acitveTab = this.form.slug;

            this.$notify({
              type: "success",
              title: "Success",
              message:"Slide has been successfully added.",
            });

            this.resetForm();

            this.saveBlocks();

          }

        });

      }

      if(action == "update"){

        this.$refs.updateSlideObserver.validate().then((success) => {
        
          if(!success){

            const errors = Object.entries(this.$refs.updateSlideObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.updateSlideObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if((this.blocks[this.updateSlideForm.slug] != undefined) &&
            this.blocks[this.updateSlideForm.slug].id != this.updateSlideForm.slideId){
              
              this.$message({
                type: "error",
                showClose: true,
                message: "The entered slug has already been used.",
              });

              return

            }else{

              let tempBlocks = _.cloneDeep(this.blocks);

              for(let key in tempBlocks){

                if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){
                  
                  if(tempBlocks[key].id == this.updateSlideForm.slideId){

                    if(key != this.updateSlideForm.slug){

                      let index = Object.keys(tempBlocks).findIndex(key => tempBlocks[key].id === this.updateSlideForm.slideId);

                      if(index !== -1){

                        let newObject = { 
                          id: tempBlocks[key].id, 
                          name: this.updateSlideForm.label,
                          active: tempBlocks[key].active,
                          fields: tempBlocks[key].fields
                        };

                        let newKey = this.updateSlideForm.slug;

                        let newTempBlocks = {};

                        Object.keys(tempBlocks).forEach((key, i) => {

                          if(i !== index){

                            newTempBlocks[key] = tempBlocks[key];

                          }

                          if(i === index){

                            newTempBlocks[newKey] = newObject;

                          }

                        });

                        tempBlocks = _.cloneDeep(newTempBlocks);

                      }

                    }else{

                      tempBlocks[this.updateSlideForm.slug].name = this.updateSlideForm.label
                      
                    }

                  }

                }

              }

              this.blocks = _.cloneDeep(tempBlocks);

              this.acitveTab = this.updateSlideForm.slug

              this.updateSlideForm.isOpen = false;

              this.$notify({
                type: "success",
                title: "Success",
                message:"Slide has been successfully updated.",
              });

            }

          }
        
        });

      }

      if(action == "addField"){

        this.$refs.addFieldObserver.validate().then(async (success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addFieldObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.addFieldObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if(this.blocks[this.acitveTab] == undefined || this.blocks[this.acitveTab]['fields'] == undefined){

              this.$message({
                type: "error",
                showClose: true,
                message: "Page not found. Kindly first create page and select it.",
              });
              
              return

            }

            if(this.blocks[this.acitveTab]['fields'][this.fieldForm.slug] != undefined){

              this.$message({
                type: "error",
                showClose: true,
                message: "The entered slug has already been used.",
              });

              return

            }

            this.fieldForm.isLoading = true;

            let obj = {
              id: uuidv4(),
              name: this.fieldForm.label,
              type: this.fieldForm.type,
              default: true,
            }

            if(this.fieldForm.type == 'image'){

              obj['alt'] = this.fieldForm.alt;
              obj['link'] = this.fieldForm.link;

              let formData = new FormData();

              formData.append('image', this.fieldForm.value);

              try{
                let res = await this.$axios.post('/content-block/image/upload', formData);
                if(res.data.status_code == "1280"){
                  
                  obj['value'] = res.data.name;

                  this.$store.commit('gallery_module/CLEAR_MODULE');

                  this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
                
                }
              }catch(ex){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: ex.response ? ex.response.data.message : ex.message,
                });

                this.fieldForm.isLoading = false;

                return

              }
              
            }else if(this.fieldForm.type == 'link'){

              obj['link'] = this.fieldForm.link;
              obj['value'] = this.fieldForm.value;
              obj['newTab'] = this.fieldForm.newTab;
              obj['color'] = this.fieldForm.color;

            }else if(this.fieldForm.type == 'text'){

              obj['value'] = this.fieldForm.value;
              obj['color'] = this.fieldForm.color;

            }

            this.$notify({
              type: "success",
              title: "Success",
              message: "Field has been successfully added.",
            });

            this.blocks[this.acitveTab]['fields'][this.fieldForm.slug] = obj;

            this.saveBlocks();

            this.resetForm();

          }

        });

      }

    },
    resetForm(){

      this.form = {
        label: '',
        slug:'',
        isOpen: false,
        active: true
      }

      this.$refs.addSlideObserver.reset();

      this.fieldForm = {
        isOpen: false,
        label: '',
        slug: '',
        type: '',
        value: '',
        isLoading: false,
        color: '#000000'
      }

      this.$refs.addFieldObserver.reset();

    },
    filterBlock(){

      let inp = this.searchedItem
      this.blocksList = this.tempBlocksList.filter((block)=>block.label.toLowerCase().includes(inp.toLowerCase()));

    },
    async handleImageAddField(e){

      const {valid} = await this.$refs.imageProvider.validate(e);

      if(valid){

        const {files} = e.target;
        this.fieldForm.value = files[0];

      }else{

        this.$refs.fieldImageUploader.value = '';

      }

    },
    async updateImageHandler(e, group, field, value){

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file name must not exceed 50 characters.",
          }); 

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
        
        }else if(file.type.includes('image/')){
          
          let uploadedFileType = file.type.slice(6);
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          return

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          return

        }

        let loader = Loading.service({
          fullscreen: true,
          text: 'Please wait we are uploading image.',
        })

        let formData = new FormData();
        formData.append('previous_image_name', value);
        formData.append('new_image', file);

        try{
          let res = await this.$axios.post('/content-block/image/update', formData);
          if(res.data.status_code == "1281"){

            let clone = _.cloneDeep(this.blocks);
            clone[group]['fields'][field]['value'] = res.data.name;
            clone[group]['fields'][field]['default'] = false;
            this.blocks = clone;

            this.saveBlocks();

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }
        }catch(ex){

          this.$message({
            type: "error",
            showClose: true,
            message: ex.response ? ex.response.data.message : ex.message,
          });

        }finally{

          loader.close();

        }

      }

    },
    async saveBlocks(){

      let loader = Loading.service({
        fullscreen: true,
        text: 'Saving blocks. Please wait!',
      });

      this.tempActiveTab = this.acitveTab;

      this.acitveTab = "";

      try{
        let res = await this.$axios.post('/store-settings/banner-blocks/update', {blocks: JSON.stringify(this.blocks)});
        if(res.data.status_code == "1177"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.$store.commit("settings_module/update_banner_blocks", res.data.blocks);

          this.$store.commit('gallery_module/CLEAR_MODULE');

        }
      }catch(ex){

        this.$message({
          type: "error",
          showClose: true,
          message: ex.response ? ex.response.data.message : ex.message,
        });

      }finally{

        loader.close();

        this.acitveTab = this.tempActiveTab;
        
      }
      
    },
    handleDeleteField(group, field){

      if(!this.tourIsActive){

        MessageBox.confirm(
          "Are you sure you want to remove the field?", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(async ()=>{

          let tempBlocks = _.cloneDeep(this.blocks);

          let tempField = tempBlocks[group]["fields"][field];

          if(tempField.type == 'image'){
          
            let formData = new FormData();

            let blockImages = [];

            let tempImage = {
              name:tempField.value
            }

            blockImages.push(tempImage);

            formData.append('images',JSON.stringify(blockImages))

            let loader = Loading.service({
              text: "Field is being removed. Please wait!",
              fullscreen: true,
            });

            try{
              let res = await this.$axios.post('/content-block/images/remove',formData);
              if(res.data.status_code == "1284"){

                loader.close();

                let blocks = _.cloneDeep(this.blocks);

                delete blocks[group]["fields"][field];

                this.blocks = blocks;
                
                this.tempActiveTab = "";

                this.saveBlocks();

              }
            }catch(error){

              loader.close();

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }

          }else{

            let blocks = _.cloneDeep(this.blocks);

            delete blocks[group]['fields'][field];

            this.blocks = blocks;

            this.tempActiveTab = "";

            this.saveBlocks();

          }

        }).catch(()=>{});

      }
      
    },

    //edit/delete page options start
    openEditSlideModal(group){

      let tempBlocks = _.cloneDeep(this.blocks);

      let selectedKey = '';
      
      let foundBlockData = null;

      for(let key in tempBlocks){

        if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){

          if(tempBlocks[key].id == group.id){

            foundBlockData = tempBlocks[key];

            selectedKey = key;

          }

        }

      }

      if(foundBlockData){

        this.updateSlideForm = {
          label: foundBlockData.name,
          slug: selectedKey,
          active: foundBlockData.active,
          slideId: foundBlockData.id,
          tempLabel: foundBlockData.name,
          tempSlug: selectedKey,
          isOpen: true,
        }

      }

    },
    async removeSlideHandle(group){

      MessageBox.confirm(
        "Are you sure you want to remove the Slide? All the associated fields will also be removed.", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "Slide is being removed. Please wait!",
          fullscreen: true,
        });

        let blocks = _.cloneDeep(this.blocks);

        let fieldImagesList = [];

        for(let key in blocks){

          if(Object.prototype.hasOwnProperty.call(blocks, key)){

            if(JSON.stringify(blocks[key]) === JSON.stringify(group)){
              
              let blockFields = _.cloneDeep(blocks[key]['fields']);

              for(let fieldKey in blockFields){

                if(Object.prototype.hasOwnProperty.call(blockFields, fieldKey)){

                  if(blockFields[fieldKey].type == 'image'){

                    let tempImageField = {
                      name: blockFields[fieldKey].value
                    }

                    fieldImagesList.push(tempImageField);

                  }

                }

              }

            }

          }

        }

        if(fieldImagesList.length > 0){

          let formData = new FormData();

          formData.append('images',JSON.stringify(fieldImagesList))

          try{
            let res = await this.$axios.post('/content-block/images/remove',formData);
            if(res.data.status_code == "1284"){

              let blocks = _.cloneDeep(this.blocks);

              for(let key in blocks){

                if(Object.prototype.hasOwnProperty.call(blocks, key)){

                  if(JSON.stringify(blocks[key]) === JSON.stringify(group)){

                    delete blocks[key];
                    break; 

                  }

                }

              }

              this.blocks = blocks;

              if(Object.keys(this.blocks).length > 0){

                this.acitveTab = Object.keys(this.blocks)[0];

              }

              loader.close();

              this.$notify({
                type: "success",
                title: "Success",
                message: "Slide has been successfully removed.",
              });

              this.saveBlocks();

            }
          }catch(error){

            loader.close();

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }

        }else{

          let blocks = _.cloneDeep(this.blocks);

          for(let key in blocks){

            if(Object.prototype.hasOwnProperty.call(blocks, key)){

              if(JSON.stringify(blocks[key]) === JSON.stringify(group)){

                delete blocks[key];
                break; 

              }

            }

          }

          this.blocks = blocks;

          if(Object.keys(this.blocks).length > 0){

            this.acitveTab = Object.keys(this.blocks)[0];

          }

          this.$notify({
            type: "success",
            title: "Success",
            message: "Slide has been successfully removed.",
          });

          loader.close();

          this.saveBlocks();

        }

      }).catch(() => {})

    },
    //edit/delete page options end

    //set images to default start
    setImageToDefaultHandle(){

      MessageBox.confirm(
        "Do you really want to set all the image fields to default?", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let tempBlocks = _.cloneDeep(this.blocks);

        for(let key in tempBlocks){
        
          if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){
          
            let blockFields = _.cloneDeep(tempBlocks[key]['fields']);

            for(let fieldKey in blockFields){

              if(Object.prototype.hasOwnProperty.call(blockFields, fieldKey)){

                if(blockFields[fieldKey].type == 'image' && !blockFields[fieldKey].default){
                  
                  blockFields[fieldKey].default = true;

                }

              }

            }

            tempBlocks[key]['fields'] = blockFields;

          }
        
        }

        this.blocks = _.cloneDeep(tempBlocks);

        this.$notify({
          type: "success",
          title: "Success",
          message: "All the image fields have been successfully set to default.",
        });

      }).catch(() => {})

    },
    //set images to default end

    //download image from image block start
    imageDownloadHandle(imgTagId,field,e){

      var imageUrl = document.getElementById(imgTagId).src;

      var a = document.createElement('a');
      a.href = imageUrl;
      a.download = field.value;
      a.target = '_blank'

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);

    },
    //download image from image block end

    //download images of all image blocks start (backend)
    async downloadBlockImagesHandle(){

      this.$prompt('Email Address*', 'Download Block Images', 
      {
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: 'A valid email address is required',
        beforeClose: async (action, instance, done) => {

          if(action === 'confirm'){

            this.$notify({
              type: "success",
              title: "Success",
              message: "Download process initiated. You'll receive an email having all the images in a zip file soon.",
            });

            done();

            let formData = new FormData();

            formData.append('email',instance._data.inputValue);
            formData.append('blocks_type','banner');

            // instance.confirmButtonText = 'Loading...';
            // instance.confirmButtonLoading = true;

            try{
              let res = await this.$axios.post('/content-block/images/download',formData);
              if(res.data.status_code == "4001"){

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: res.data.message,
                });

              }
            }catch(error){

              if(error.response){

                if(error.response.data.error.includes('failed') ||
                error.response.data.error.includes('associated') ||
                error.response.data.error.includes('unsuccessful')){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.error,
                  });

                }else{

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.message,
                  });

                }

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.message,
                });

              }

            }finally{

              // instance.confirmButtonText = 'Continue';

              // instance.confirmButtonLoading = false;

              // done();

            }

          }else{

            done();

          }

        }
      }).then(({ value }) => {

      }).catch(() => { });

    },
    //download images of all image blocks end

    //tour methods
    startHomeBannerBlocksTour(){

      this.$tours.homeBannersTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){

    },
    handlePreviousStep(currentStep){

    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },

    //environment check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true

      }else{

        return false

      }

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

  },
  mounted(){

  },
  async beforeMount(){

    let obj = this.settings.banner_blocks ? JSON.parse(this.settings.banner_blocks) : {};
    
    if(Object.keys(obj).length > 0){
      
      if(this.tempActiveTab == ""){

        this.acitveTab = Object.keys(obj)[0];

      }else{

        this.acitveTab = this.tempActiveTab

      }

    }

    this.blocks = obj;
    
  },
  beforeDestroy(){

  }
};
</script>
<style scoped>
  .tabsListSection{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .tabsListSection ul{
    flex:1;
    list-style: none;
    border-right: 1px solid #eee;
    box-sizing: border-box;
    background-color: white;
  }
  .tabsListSection ul li{
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .tabsListSection ul li button{
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    position: relative;
  }
  .tabsListSection .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: white;
    border-radius: 0;
    color: #1ec2c2;
  }
  .tabsContentSection .tabsContent .tabCardBox{
    width: 32% !important;
  }

  @media (max-width: 1024px) {

    .tabCardBox {
      width: 35.2%;
    }

  }
  @media (max-width: 991px) {

    .tabMainBox {
      padding: 20px;
      gap: 24px;
    }
    .addNewOrderBtn button {
      padding: 8px 14px;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .tabsContentSection .tabPageSection{
      padding: 20px;
    }

  }
  @media (max-width: 767px) {

    .tabMainBox {
      padding: 15px;
      gap: 24px;
    }

    .tabCardBox {
      width: 100%;
    }
    .tabsContentSection{
      flex-direction: column;
    }
    .tabsContentSection .tabsContent{
      width: 100%;
    }
    .tabsContentSection .tabPageSection{
      width: 100%;
      padding: 15px;
    }
    .tabsContentSection .tabsContent .tabCardBox{
      width: 32%!important;
    }

  }
  @media (max-width: 576px) {

    .tabsContentSection .tabsContent .tabCardBox{
      width: 100% !important;
    }
    
  }


  .viewOrderBox tbody tr td {
    cursor: inherit !important;
  }
  .category-tab-img {
    height: 400px;
    width: 400px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .category-tab-img:hover {
    border-color: #409EFF;
  }
  .category-tab-img img{
    height: 100%;
    width: 100%;
  }
  .category-tab-img svg{
    position: absolute;
    width: 50px;
    top: 43%;
    left: 0;
    right: 0;
    margin: 0 auto;
    fill: #d9d9d9;
  }
  .category-tab-img:hover svg{
    fill: #409EFF;
  }
  .custom-form.create-section .ql-editor {
    color: #000 !important;
  }
  .ql-editor{
    height: 200px;
  }
  .el-message{
    z-index: 99999 !important;
  }
  .tabCardBox{
    width: 31.9%;
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 0px;
    border: none;
    box-shadow: 0px 3px 15px 0px rgb(0 0 0 / 16%);
    position: relative;
  }
  .tabMainBox{
    padding: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }
  .fieldBox{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .fieldBox:nth-last-child(1){
    margin-bottom: 0px;
  }
  .fieldBox label {
    font-size: 18px;
    color: #adadad;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 5px;
  }
  .fieldBox input{
    width: 82%;
    border-radius: 4px;
    background: #ededed;
    border: none;
    padding: 8px 11px;
  }
  .fieldBox input[type="color"]{
    height: 30px;
    padding: 0px !important;
    width: 60px;
    border: none;
    margin-right: auto;
    margin-left: 30px;
  }
  .fieldImage{
    width: 100%;
    height: 175px;
    border: 1px dashed #c7c7c7;
    border-radius: 0px;
    margin-top: 0px;
    position: relative;
  }
  .fieldImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .uploaderNewImg{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    background: #1EC2C2;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploaderNewImg input{
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .uploaderNewImg svg{
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-left: 1px solid #eee;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #eee;
    border-bottom: none;
    border-radius: 0;
  }
  .el-tabs--card>.el-tabs__header {
    border-bottom: 1px solid #eee;
  }
  .el-tabs__item:hover{
    color: #303133;
  }
  .el-tabs__item.is-active {
    color: #1EC2C2;
    background: transparent;
    /* border-bottom: 1px solid #14223d !important; */
  }
  .el-tabs__nav-wrap{
    margin-bottom: 0px;
  }
  .fieldBox input:disabled{
    opacity: 0.7;
  }
  /* .tabCardBox .fieldBox:nth-child(2) input:disabled{
    opacity: 1;
    color: #000;
    font-weight: 500;
  } */
  .tabCardBox .fieldBox:nth-last-child(1) input{
    font-weight: 500;
  }
  .tabCardHeader{
    background-color: #E6E6E6;
    padding: 8px 2px 8px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tabCardHeader h5{
    font-size: 16px;
    color: #14223d;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .cardBodyBlock{
    padding: 21px;
  }
  .fieldBox textarea{
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    min-height: 175px;
    max-height: 175px;
    width: 100%;
    padding: 11px 13px;
    font-weight: 400;
    color: #000;
  }
  .blockIconBox{
    width: 30px;
    height: 30px;
    display: inline-block;
    /* background: #fff; */
    margin-left: 0px;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .tabCardHeader h5:nth-last-child(1){
    display: flex;
    align-items: center;
    margin-right: 0px;
  }
  .tabCardHeader h5 span svg{
    width: 100%;
    height: 100%;
    fill: #1EC2C2;
  }
  .viewImgIcon{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #1EC2C2;
    position: absolute;
    top: 42px;
    right: 8px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
  .viewImgIcon svg{
    widows: 100%;
    height: 100%;
    fill: #fff;
  }
  .switch-div{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
  .switch-div .switch-label{
    font-size: 14px; 
    margin-top: 3px
  }

  @media(max-width: 1024px){
    .tabCardBox {
      width: 48.2%;
    }
  }
  @media(max-width: 991px){
    .tabMainBox {
      padding: 20px;
      gap: 24px;
    }
    .addNewOrderBtn button {
      padding: 8px 14px;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  @media(max-width: 767px){
    .tabMainBox {
      padding: 15px;
      gap: 24px;
    }
    .tabCardBox {
      width: 100%;
    }
  }
  .formContentBox input[type='checkbox']{
    width: auto !important;
    margin-left: 5px;
  }
  .formContentBox input[type='color']{
    width: 30px !important;
    margin-left: 5px;
    padding: 0 !important;
    height: 30px;
  }
  .ctmCloseBtn {
    background-color: transparent;
    padding: 0px;
    border: none;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    right: 8px;
  }
  .ctmCloseBtn svg {
    width: 100%;
    height: 100%;
  }

  .viewOrderBox {
    overflow: hidden;
    box-shadow: 1px 1px 11px #e8e8e8;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #fff;
    padding: 0px;
    position: relative;
  }

  .downloaderNewImg{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    background: #1ec2c2;
    position: absolute;
    top: 80%;
    right: 8px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .downloaderNewImg svg{
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  .el-message-box .el-input input{
    padding-left: 10px !important
  }
  .el-message-box .el-message-box__input{
    padding-top: 5px !important;
  }
  .noFieldsWrap{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #000;
  }
</style>