<template>
    <div class="right-menu shipping-right">

        <subHeader pageName="DROP SHIPPING" />

        <tutorialsAction />

        <div class="paymentSection">
            <div class="db-container">
                <div class="paymentMainBlock">

                    <!-- cj dropshipping starts here -->
                    <div class="paymentMethodWrap" id='cj-dropshipping-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>CJ Dropshipping</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="cj-dropshipping-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="cjDropshipping.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('cj-dropshipping')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="cjDropshippinObserver">
                            <div class="bankingFormField" :class="cjDropshipping.isActive ? 'expandForm' : ''" id="cj-dropshipping-form">
                                <form @submit.prevent="validate('cj-dropshipping')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="email address" rules="required|email" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="cj-dropshipping-email-address">Email Address <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="cj-dropshipping-email-address" 
                                                        v-model="cjDropShippingData.email" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="API key" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="cj-dropshipping-api-key">API Key <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="cj-dropshipping-api-key" 
                                                        v-model="cjDropShippingData.apiKey" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn stripe dropshipping">
                                                <div class="message">
                                                    <div class="paymentNote">
                                                        <p class="fieldSeoMessage">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Generate your API key from CJ dropshipping dashboard > Authorization > API > API Key
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="actions">
                                                    <loaderBtn v-if="productsLoader"/>
                                                    <Button 
                                                        type="button" 
                                                        id="cj-dropshipping-products-btn" 
                                                        @click.prevent="checkedItems = [], cjProductsModal.isOpen = true" 
                                                        :disabled="tourIsActive || cjDropShippingLoader"
                                                        v-else
                                                    >
                                                        Products
                                                    </Button>
                                                    <loaderBtn v-if="cjDropShippingLoader"/>
                                                    <Button id="cj-dropshipping-save-btn" :disabled="tourIsActive || productsLoader" v-else>Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- cj dropshipping ends here -->

                </div>
            </div>
        </div>

        <!-- cj products modal starts here -->
        <div class="modalBg" :class="cjProductsModal.isOpen ? 'modal-active' : '' ">
            <div class="modalLayers" @click="cjProductsModal.isOpen = false"></div>
            <div class="modalBody">
                <div class="addProductTitle">
                    <h3>CJ Dropshipping Products</h3>
                </div>
                <div class="searchProductForm" style="margin-bottom:0px !important">
                    <input
                        type="search"
                        name="searchProduct"
                        id="searchProduct"
                        placeholder="Search"
                        v-model="searchedProductKeyword"
                        @input="loadProducts('cj-dropshipping','search',1)"
                        :disabled="cjProductsModal.pending"
                    />
                </div>
                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;">
                    <div id="London" class="tabcontent">
                        <div class="content-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <!-- <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox" 
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == currentProductList.length"
                                                        :disabled="tourIsActive"
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div> -->
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product Image">
                                                        Image
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product Name">
                                                        Name
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product Category Name">
                                                        Category
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product Name">
                                                        SKU
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product Pricing">
                                                        Pricing
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="cjProductsModal.pending">
                                    <tr v-for="i in 5" :key="'loader_' + i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="17"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                    type="circle"
                                                    :width="36"
                                                    :height="36"
                                                    animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="cjProductsModal.productsList.length == 0">
                                        <td colspan="6" style="text-align: center">
                                            <p class="mb-0">No Products Available</p>
                                        </td>
                                    </tr>   

                                    <tr v-for="(product, index) in cjProductsModal.productsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer" @click.prevent="checkedItemHandle(product)">
                                                    <input type="checkbox" :checked="isCheckedItem(product.pid)" :disabled="tourIsActive">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- product image block starts here -->
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div style="width: 100%;">
                                                    <div class="ctmDataTableImgBox">
                                                        <img :src=" product.productImage ? product.productImage : '/images/no_image.png'"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- product image block ends here -->

                                        <!-- product name block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ product.productNameEn ? product.productNameEn.length > 35 ? 
                                                    product.productNameEn.substr(0,35) + "..." : product.productNameEn : '' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product name block ends here -->

                                        <!-- product category block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ product.categoryName ? product.categoryName.length > 20 ? 
                                                    product.categoryName.substr(0,20) + "..." : product.categoryName : '' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product category block ends here -->

                                        <!-- product sku block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ product.productSku ? product.productSku.length > 20 ? 
                                                    product.productSku.substr(0,20) + "..." : product.productSku : '' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product sku block ends here -->

                                        <!-- product pricing block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ product.sellPrice ? product.sellPrice.length > 20 ? 
                                                    product.sellPrice.substr(0,20) + "..." : product.sellPrice : '' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product pricing block ends here -->

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <hr v-if="cjProductsPagination.total > 10">
                
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="cjProductsPagination.total"
                    @current-change="productsPaginateChangeHandle"
                    class="mt-4"
                    v-if="cjProductsPagination.total > 10"
                />

                <hr>

                <div class="modalDoneButton">
                    <button @click.prevent="initiateCjProductImport" :disabled="checkedItems.length == 0">
                        Import
                    </button>
                </div>
                <div class="modalCloseIcon">
                    <button @click="cjProductsModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- cj products modal ends here -->

        <!--vue tour-->
        <v-tour name="dropshippingTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";


export default {
    metaInfo(){
        return{
            title: 'Marketing | Dropshipping | YeetCommerce',
        };
    },
    data(){
        return{

            //cj dropshipping start
            cjDropshipping: {},
            tempCjDropshipping: {},
            cjDropShippingData: {
                email: "",
                apiKey: "",
                accessToken: "",
            },
            cjDropShippingLoader: false,
            //cj dropshipping end

            //cj products start
            cjProductsModal:{
                isOpen: false,
                productsList:[],
                pending: false,
            },
            cjProductsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
            },
            searchedProductKeyword: "",
            productsLoader: false,
            timer: undefined,
            checkedItems:[],
            //cj products end

            //vue tour
            tourOptions:{
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks:{
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //dropshipping steps
                {
                    target: '#cj-dropshipping-step',
                    content: `<strong>CJ Dropshipping</strong><br>CJ dropshipping configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#cj-dropshipping-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the CJ dropshipping can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#cj-dropshipping-email-address',
                    content: `<strong>CJ Dropshipping Email Address</strong><br>CJ dropshipping email address can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#cj-dropshipping-api-key',
                    content: `<strong>CJ Dropshipping API Key</strong><br>CJ dropshipping API key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#cj-dropshipping-save-btn',
                    content: `<strong>Save Details</strong><br>CJ dropshipping details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#cj-dropshipping-products-btn',
                    content: `<strong>CJ Dropshipping Products</strong><br>CJ dropshipping products can be accessed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive:false,
            
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
    },
    watch:{
        'cjProductsModal.isOpen': function(newValue, oldValue){

            if(newValue && this.checkedItems.length == 0){

                this.searchedProductKeyword = "";

                this.cjProductsPagination = {
                    current_page: 0,
                    total: 0,
                    previous_page: 0,
                },

                this.checkedItems = [];

                this.loadProducts('cj-dropshipping','simple', 1);

            }

        },
    },
    methods:{

        validate(form){
        
            if(form == 'cj-dropshipping'){

                this.$refs.cjDropshippinObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.cjDropshippinObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.cjDropshippinObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updateDropshippingHandle(form);

                    }

                });

            }
        
        },

        async updateDropshippingHandle(form){

            let formData = new FormData();

            if(form == 'cj-dropshipping'){

                formData.append('platform_id',this.cjDropshipping.id);
                formData.append('email_address',this.cjDropShippingData.email);
                formData.append('apiKey',this.cjDropShippingData.apiKey);
                formData.append('isActive',this.cjDropshipping.isActive ? 1 : 0);

                this.cjDropShippingLoader = true;

            }

            let loader = Loading.service({
                text: "The dropshipping platform is being updated. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/dropshipping/update',formData);
                if(res.data.status_code == "1811"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('limit reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

                if(form == 'cj-dropshipping'){

                    this.cjDropShippingLoader = false;

                }

                this.loadDropshippingData();

            }

        },

        async toggleProceedableHandle(form){
        
            if(form == 'cj-dropshipping'){

                //validating the cj dropshipping form
                this.$refs.cjDropshippinObserver.validate().then(async (success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.cjDropshippinObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.cjDropshippinObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                        this.$notify({
                            type: 'info',
                            title: 'Information Missing',
                            message: "Kindly enter required information in the CJ dropshipping and save it to enable it.",
                        });

                        this.$refs.cjDropshippinObserver.reset();

                    }else{

                        if(this.cjDropshipping.isActive != this.tempCjDropshipping.isActive){

                            this.dropshippingStatusToggle(form);

                        }

                    }

                });

            }
        
        },
        async dropshippingStatusToggle(form){

            let formData = new FormData();

            if(form == 'cj-dropshipping'){

                formData.append('platform_id',this.cjDropshipping.id)
                formData.append('isActive',this.cjDropshipping.isActive ? 1 : 0);

            }

            let loader = Loading.service({
                text: "The dropshipping platform is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/dropshipping/toggle',formData)
                if(res.data.status_code == '1600' || res.data.status_code == '1601'){
                    
                    if(form == 'cj-dropshipping'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `CJ Dropshipping has been ${res.data.message}`,
                        });

                    }

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

                this.loadDropshippingData();

            }

        },

        async loadDropshippingData(){

            let loader = Loading.service({
                text: "Fetching dropshipping platforms. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.get('/dropshipping/all');
                if(res.data.status_code == "1810"){

                    res.data.dropshipping_records.forEach((listedDropshippingRecord) => {

                        if(listedDropshippingRecord.platform == 'CJ Dropshipping'){

                            this.cjDropshipping = listedDropshippingRecord;

                            this.tempCjDropshipping = _.cloneDeep(this.cjDropshipping);

                            let cjDropShippingData = JSON.parse(this.cjDropshipping.data);

                            this.cjDropShippingData = cjDropShippingData;

                        }

                    })


                }
            }catch(error){

                this.$message({
                    type: 'Error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

            }

        },

        //product methods start here
        productsPaginateChangeHandle(currentPage, size){

            this.loadProducts('cj-dropshipping', 'simple', currentPage);

        },
        async loadProducts(form, sub_action, page){

            if(form == 'cj-dropshipping'){

                if(sub_action == 'search'){

                    clearTimeout(this.timer);

                    this.cjProductsModal.pending = true;

                    this.timer =  setTimeout(async () => {
                        try{
                            let res = await this.$axios.get('/cj-dropshipping/product/all',{
                                params:{
                                    page: page,
                                    size: 10,
                                    search: this.searchedProductKeyword ? this.searchedProductKeyword : null,
                                }
                            });
                            if(res.data.status_code == "1007"){

                                this.cjProductsModal.productsList = res.data.products.data.list;

                                this.cjProductsPagination = {
                                    current_page: res.data.products.data.pageNum,
                                    total: Math.round(res.data.products.data.total / 10),
                                    previous_page: res.data.products.data.pageNum > 1 ? res.data.products.data.pageNum - 1 : 1,
                                }  

                            }
                        }catch(error){

                            if(error.response){

                                if(error.response.data.error.includes('must be connected') ||
                                error.response.data.error.includes('has been lost') || 
                                error.response.data.error.includes('retrieving the products')){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error,
                                    });

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.message,
                                    });
                                    
                                }

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.message,
                                });  

                            }

                        }finally{

                            this.cjProductsModal.pending = false;

                        }

                    });

                }else if(sub_action == 'simple'){

                    this.cjProductsModal.pending = true;
                    try{
                        let res = await this.$axios.get('/cj-dropshipping/product/all',{
                            params:{
                                page: page,
                                size: 10,
                            }
                        });
                        if(res.data.status_code == "1007"){

                            this.cjProductsModal.productsList = res.data.products.data.list;

                            this.cjProductsPagination = {
                                current_page: res.data.products.data.pageNum,
                                total: Math.round(res.data.products.data.total / 10),
                                previous_page: res.data.products.data.pageNum > 1 ? res.data.products.data.pageNum - 1 : 1,
                            }                        

                        }
                    }catch(error){

                        this.cjProductsModal.productsList = [];
                        this.cjProductsModal.isOpen = false;

                        if(error.response){

                            if(error.response.data.error.includes('must be connected') ||
                            error.response.data.error.includes('has been lost') ||
                            error.response.data.error.includes('retrieving the products')){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });
                                
                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });  

                        }

                    }finally{

                        this.cjProductsModal.pending = false;

                    }

                }

            }

        },
        checkedItemHandle(product){
            
            if(!this.tourIsActive){

                let index = this.checkedItems.findIndex(item => item.id == product.pid);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push({
                        id: product.pid,
                        name: product.productNameEn
                    });

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if((this.checkedItems.length == this.cjProductsModal.productsList.length) ||
                (this.checkedItems.length == this.cjProductsModal.productsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.cjProductsModal.productsList.length){

                    this.checkedItems = [];
                    this.cjProductsModal.productsList.forEach((product) => {

                        if(product.id != -1 && (typeof product.id != 'undefined')){

                            this.checkedItems.push({
                                id: product.id,
                                name: product.productNameEn,
                            });

                        }

                    });

                }

            }

        },
        isCheckedItem(productId){

            let find = this.checkedItems.find( x => x.id == productId);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        initiateCjProductImport(){

            this.cjProductsModal.isOpen = false;

            MessageBox.confirm(
                "Are you sure you want to initiate the product import process? The quantity of all the product variations will be set to 1 by default.", 
                "Warning",
                {
                    type: "warning",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }
            ).then(async () => {

                let formData = new FormData();

                formData.append('products',JSON.stringify(this.checkedItems));

                let loader = Loading.service({
                    text: "Submitting product(s) import request. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/cj-dropshipping/product/import',formData);
                    if(res.data.status_code == "1362"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: res.data.message,
                        });

                        this.checkedItems = [];

                    }
                }catch(error){

                    this.cjProductsModal.isOpen = true;

                    if(error.response){

                        if(error.response.data.error.includes('must be connected') || 
                        error.response.data.error.includes('has been lost')){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }).catch((ex) => {

                this.cjProductsModal.isOpen = true;

            });

        },
        //product methods end here

        //tour methods
        startDropshippingTour(){

            this.$tours.dropshippingTour.start();

            this.tourIsActive = true;

            this.cjDropshipping.isActive = false;

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 6){

                this.cjDropshipping.isActive = true;

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 6){

                this.cjDropshipping.isActive = false;

            }

        },
        handleTourStop(){

            if(this.$tours.dropshippingTour && this.$tours.dropshippingTour.currentStep != -1){
            
                this.loadDropshippingData();
                
                this.tourIsActive = false;

            }

        },
        handleTourFinish(){

            this.loadDropshippingData();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            if(this.$tours.dropshippingTour && this.$tours.dropshippingTour.currentStep != -1){
            
                this.loadDropshippingData();

                this.tourIsActive = false;

            }

        },

    },
    beforeMount(){
        
        //loading the dropshippings data
        this.loadDropshippingData();
    
    },
}
</script>

<style scoped>
    .paymentSaveBtn.dropshipping .actions{
        display: flex;
        gap: 10px;
    }
    .el-pagination{
        display: flex;
        justify-content: center;
    }
    .paymentSaveBtn.stripe{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .paymentSaveBtn.stripe .message{
        display: flex;
        align-items: flex-end;
    }
</style>