<template>
    <div class="right-menu shipping-right billSection">

      <subHeader pageName="PROFILE"/>

      <tutorialsAction moduleName="PROFILE"/>

      <div class="db-blue-section">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="db-container">

            <!-- profile form starts here -->
            <div class="billFormMainBlock mt-0 mb-3">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="uploadNewUserImg storeLogoImg">
                      <div class="userDetailsImg">
                        <img :src="adminImage.image_src ? adminImage.image_src : storeAdmin.image ? base_image_url + $store.state.user.store_id + '/user/' + storeAdmin.image : '/images/customer_noImage.png'" alt="">
                      </div>
                      <div class="uploadImgFile">
                        <input 
                          type="file" 
                          name="storeLogo"
                          accept="image/png,image/jpg,image/jpeg,image/webp" 
                          @change.prevent="changeAddImage($event)"
                        >
                        <button>
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                          </svg>
                        </button>
                      </div>
                      <p class="storeLogoLabel">Image</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="first name" rules="required|max:50" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="firstName">First Name <span>*</span></label>
                        <input 
                          type="text" 
                          id="firstName" 
                          name="firstName" 
                          class="form-control"
                          placeholder="First name" 
                          v-model="storeAdmin.first_name" 
                          @input="handleFormat('add','f_name')"
                        >
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="last name" rules="required|max:50" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="lastName">Last Name <span>*</span></label>
                        <input 
                          type="text" 
                          id="lastName" 
                          name="lastName" 
                          class="form-control" 
                          placeholder="Last name"
                          v-model="storeAdmin.last_name" 
                          @input="handleFormat('add','l_name')"
                        >
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="emailInput">
                      <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                        <div class="formContentBox">
                          <label for="ownerEmail">Email <span>*</span></label>
                          <el-input 
                            v-model="storeAdmin.email" 
                            placeholder="Email address"
                            size="small"
                            disabled
                          >
                            <template #append>
                              <el-button @click="openEmailChangePopup()">Change</el-button>
                            </template>
                          </el-input>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6" id="mobile-no-field">
                    <ValidationProvider name="contact no." :rules="countryIsSelected ? 'required' : '' " v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="ownerContactNo">Contact No. <span>*</span></label>
                        <vue-tel-input 
                          v-model="storeAdmin.mobile_no"
                          mode="international"
                          :onlyCountries="allowedCountries"
                          :inputOptions="ContactFieldOptions"
                          ref="contactNoField"
                          :dropdownOptions="ContactFieldDropDownOptions"
                          :validCharactersOnly=true 
                          @input="contactInputHandle"
                          @validate="validateContactNo"
                          @country-changed="countryChangeHandle"
                          @focus="contactFieldFocusHandle"
                          @open="dropDownOpenHandle"
                          @close="dropDownCloseHandle"
                        >
                        </vue-tel-input>
                        <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                        <span class="text-danger" v-else-if="!countryIsSelected && contactFieldTouched">
                          Select a country from the dropdown menu or add the country code before the contact no.
                        </span>
                        <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">
                          Enter a valid contact no.
                        </span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="address line 1" rules="required|max:250" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="ownerAddressL1">Address Line 1 <span>*</span></label>
                        <input 
                          type="text" 
                          id="ownerAddressL1" 
                          name="ownerAddressL1" 
                          class="form-control" 
                          placeholder="Address line 1"
                          v-model="storeAdmin.address_line_1"
                        >
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="address line 2" rules="max:250" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="ownerAddressL2">Address Line 2 </label>
                        <input 
                          type="text" 
                          id="ownerAddressL2" 
                          name="ownerAddressL2" 
                          class="form-control" 
                          placeholder="Address line 2"
                          v-model="storeAdmin.address_line_2"
                        >
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="ctmFeaturedCheckBox" id="product-is-Featured">
                      <label class="ctmFeaturedContainer">
                        Two Factor Authentication
                        <input 
                          type="checkbox" 
                          v-model="storeAdmin.two_factor_enabled" 
                          @change="twoFactorChangeHandle"
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- profile form ends here -->

            <!-- save profile starts here -->
            <div class="addProfileTableRowBtn">
              <loaderBtn v-if="isLoading"/>
              <div class="saveBtn" v-else>
                <button @click="handleSubmit(saveProfile)">SAVE</button>
              </div>
              <button @click="openPasswordForm">CHANGE PASSWORD</button>
              <div class="csvBtn">
                <loaderBtn v-if="socialLoginLoader"/>
                <div class="btn-group" v-else>
                  <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Social Logins
                    <svg height="20px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <polygon points="396.6,160 416,180.7 256,352 96,180.7 115.3,160 256,310.5 "/>
                    </svg>
                  </button>
                  <div class="dropdown-menu" v-if="!socialLoginLoader">
                    <a class="dropdown-item" @click.prevent="googleConnect">
                      Connect a Google Account
                    </a>
                    <a class="dropdown-item" @click.prevent="microsoftConnectInitiate" v-if="isDev()">
                      Connect a Microsoft Account
                    </a>
                  </div>
                </div>
              </div> 
            </div>
            <!-- save profile ends here -->

            <div class="ownerInformation" style="margin-top:20px;">
              <p>SOCIAL LOGIN CONNECTIONS</p>
            </div>

            <!-- social login table starts here -->
            <div class="tableMainWrapBox" style="position: relative;" id="preview-record">
              <div class="tableScrollWrap" ref="brandsTable">
                <table>
                  <thead>
                    <tr>
                      <th class="ctmPr-3">
                        <div class="ctmTableTitleWrap">
                          <!-- <p data-toggle="tooltip" data-placement="top" title="Sr no.">Sr No.</p> -->
                        </div>
                      </th>
                      <th class="ctmPr-3">
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Full Name">Name</p>
                        </div>
                      </th>
                      <th class="ctmPr-3">
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                        </div>
                      </th>
                      <th class="ctmPr-3">
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Social Platform">Platform</p>
                        </div>
                      </th>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="storeAdmin.social_logins && storeAdmin.social_logins.length == 0">
                      <td colspan="6" style="text-align:center"><p class="mb-0">No Social Login Connections Available</p></td>
                    </tr>
                    <tr v-for="(socialLogin, index) in storeAdmin.social_logins" :key="index">

                      <!-- blank block starts here -->
                      <td>
                        <div class="tableBodyCtmTxt" data-open="true">
                        </div>
                      </td>
                      <!-- blank block ends here -->

                      <!-- first name block starts here -->
                      <td>
                        <div class="tableBodyCtmTxt" data-open="true">
                          <p data-open="true">{{ socialLogin.first_name + " " + socialLogin.last_name }}</p>
                        </div>
                      </td>
                      <!-- first name block ends here -->

                      <!-- last name block starts here -->
                      <td>
                        <div class="tableBodyCtmTxt" data-open="true">
                          <p data-open="true">{{ socialLogin.email }}</p>
                        </div>
                      </td>
                      <!-- last name block ends here -->

                      <!-- social platform block starts here -->
                      <td>
                        <div class="tableBodyCtmTxt" data-open="true">
                          <p data-open="true">{{ socialLogin.social_platform }}</p>
                        </div>
                      </td>
                      <!-- social platform block ends here -->
                      
                      <!-- action block starts here -->
                      <td>
                        <div class="ctmDataTableActionBtn" v-if="isDeletingItem(socialLogin.id) && deleteLoader" data-open="true">
                          <loaderBtn />
                        </div>
                        <div class="ctmDataTableActionBtn" data-open="true" v-else>
                          <button class="delFunction" @click.prevent="deleteSocialLoginHandle(socialLogin,index)" data-open="false">
                            <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                          </button>
                        </div>
                      </td>
                      <!-- action block ends here -->

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- social login table ends here -->
            
          </div>
        </ValidationObserver>
      </div>

      <!-- password reset modal starts here -->
      <div class="modalBg passwordResetPopup" :class="passwordForm.isOpen ? 'modal-active': ''">
        <div class="modalLayers" @click.prevent="passwordForm.isOpen = false"></div>
        <div class="modalBody">
          <div class="productSideBox" style="position:relative; top:15px; right:0; width:100%;">
            <ValidationObserver ref="adminUpdatePasswordObserver">
              <form id="password-change" @submit.prevent="validate('updatePassword')">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="current password" rules="required" vid="current_password" v-slot="{ errors }" >
                      <div class="formContentBox">
                        <label class="formLabelCustomer" for="current-password">Current Password <span>*</span></label>
                        <input 
                          :type="passwordForm.currentPasswordFieldType ? 'password' : 'text'" 
                          class="form-control" 
                          id="current-password"
                          autocomplete="true"
                          placeholder="Current password"
                          form="password-change"
                          v-model="passwordForm.current_password" 
                        >
                        <span class="text-danger" >{{ errors[0] }}</span>
                        <button 
                          class="changePasswordEyeBtn"
                          type="button"
                          @click.prevent="passwordForm.currentPasswordFieldType = !passwordForm.currentPasswordFieldType"
                        >
                          <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordForm.currentPasswordFieldType">
                            <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                            <g id="eyeOpen" transform="translate(-355 -428)">
                              <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                              </g>
                              <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider name="new password" rules="required" vid="password" v-slot="{ errors }" >
                      <div class="formContentBox">
                        <label class="formLabelCustomer" for="new-password">New Password <span>*</span></label>
                        <input 
                          :type="passwordForm.passwordFieldType ? 'password' : 'text'" 
                          class="form-control" 
                          id="new-password"
                          autocomplete="true"
                          placeholder="New password"
                          form="password-change"
                          v-model="passwordForm.password" 
                        >
                        <span class="text-danger" >{{ errors[0] }}</span>
                        <button 
                          class="changePasswordEyeBtn"
                          type="button" 
                          @click.prevent="passwordForm.passwordFieldType = !passwordForm.passwordFieldType"
                        >
                          <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordForm.passwordFieldType">
                            <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                            <g id="eyeOpen" transform="translate(-355 -428)">
                              <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                              </g>
                              <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider name="confirm password" rules="required|confirmed:password" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label class="formLabelCustomer" for="confirm-password">Confirm Password <span>*</span></label>
                        <input 
                          :type="passwordForm.confirmPasswordFieldType ? 'password' : 'text'" 
                          class="form-control" 
                          id="confirm-password"
                          autocomplete="true"
                          placeholder="Confirm password"
                          form="password-change"
                          v-model="passwordForm.confirm_password" 
                        >
                        <span class="text-danger" >{{ errors[0] }}</span>
                        <button 
                          class="changePasswordEyeBtn"
                          type="button" 
                          @click.prevent="passwordForm.confirmPasswordFieldType = !passwordForm.confirmPasswordFieldType"
                        >
                          <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordForm.confirmPasswordFieldType">
                            <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                            <g id="eyeOpen" transform="translate(-355 -428)">
                              <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                              </g>
                              <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <div class="formCustomerBtn">
                      <loaderBtn v-if="passwordForm.isLoading"/>
                      <button v-else type="submit">Update password</button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="passwordForm.isOpen = false">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- password reset modal ends here -->

      <!-- email change modal starts here -->
      <div class="modalBg passwordResetPopup" :class="emailChangePopup.isOpen ? 'modal-active': ''">
        <div class="modalLayers" @click.prevent="emailChangePopup.isOpen = false"></div>
        <div class="modalBody">
          <div class="productSideBox" style="position:relative; top:15px; right:0; width:100%;">
            <ValidationObserver ref="adminUpdateEmailObserver">
              <form @submit.prevent="validate('updateEmail')">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="new email" rules="required|email" vid="email" v-slot="{ errors }" >
                      <div class="formContentBox">
                        <label class="formLabelCustomer">New Email <span>*</span></label>
                        <input 
                          type="text" 
                          class="form-control" 
                          v-model="emailChangePopup.new_email" 
                          placeholder="New email"
                        >
                        <span class="text-danger" >{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <div class="formCustomerBtn">
                      <loaderBtn v-if="emailChangePopup.isLoading"/>
                      <button v-else type="submit">Submit Request</button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="emailChangePopup.isOpen = false">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- email change modal ends here -->

      <!-- start of Google 2FA connection popup -->
      <div class="modalBg passwordResetPopup" :class="authenticatorForm.isOpen ? 'modal-active': ''">
        <div class="modalLayers" @click.prevent="closeAuthenticatorForm('forceClose')"></div>
        <div class="modalBody">
          <div class="productSideBox" style="position:relative; top:15px; right:0; width:100%;">
            <ValidationObserver ref="userAuthenticate">
              <form @submit.prevent="validate('userAuthenticator')">
                <div class="row">
                  <div class="col-md-12">
                    <div class="formContentBox">
                      <p>Scan the <b>QR Code</b> with <b>Google Authenticator</b> app and type the OTP below to complete the configuration of two-factor authentication.</p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="QRContainer">
                      <vue-qr :text="qrCode.url" :size="300"></vue-qr>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider name="OTP" rules="required|numeric|min:6" v-slot="{ errors }">
                      <div class="formContentBox otp">
                        <OtpInput
                          ref="otpInput"
                          input-classes="otp-input"
                          separator="-"
                          :num-inputs="6"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          v-model="authenticatorForm.otp"
                          @on-change="handleOtpChange"
                          @on-complete="handleOtpComplete"
                        />
                        <span class="text-danger" >{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <div class="formCustomerBtn">
                      <loaderBtn v-if="authenticatorForm.isLoading"/>
                      <button v-else type="submit">Authenticate</button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closeAuthenticatorForm('forceClose')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- end of Google 2FA connection popup -->

    </div>
</template>
  
<script>
import { MessageBox, Message, Loading } from 'element-ui'
import {mapGetters} from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import contactInput from "@/components/contactInput.vue";
import _ from "lodash";
import VueQr from 'vue-qr';
import OtpInput from "@bachdgvn/vue-otp-input";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name:'profile',
  metaInfo(){
    return {
      title: 'Profile | YeetCommerce',
    };
  },
  data:()=>({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    storeAdmin:{
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      mobile_no: '',
      city:'',
      address_line_1: '',
      address_line_2: '',
      image: '',
      image_src: '',
      two_factor_enabled:false,
      login_logs:[],
      isLoading: false,
      isActive: true,
      isOpen:true
    },
    tempStoreAdmin:{
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      mobile_no: '',
      city:'',
      address_line_1: '',
      address_line_2: '',
      image: '',
      image_src: '',
      two_factor_enabled:false,
      login_logs:[],
      isLoading: false,
      isActive: true,
      isOpen:true
    },
    adminImage:{
      image:'',
      image_src:'',
    },
    passwordForm: {
      password: '',
      confirm_password: '',
      current_password: '',
      passwordFieldType:true,
      confirmPasswordFieldType:true,
      currentPasswordFieldType:true,
      id: '',
      isOpen: false,
      isLoading: false
    },
    emailChangePopup:{
      isOpen:false,
      isLoading:false,
      new_email:""
    },
    store_url: process.env.VUE_APP_STORE_IMAGE,
    isLoading: false,
    imageValidations:{
      imgIsRequired:false,
      imgInvalidFormat:false,
      isNotImg:false,
    },
    countriesList:[],
    allowedCountries:[],
    ContactFieldOptions:{
      placeholder:"Enter mobile number",
      showDialCode:true,
      type:'tel',
      maxlength:25,
    },
    ContactFieldDropDownOptions:{
      showSearchBox:true,
      showDialCodeInSelection:false,
      showDialCodeInList:true,
      showFlags:true,
      placeholder:"Select a Country"
    },
    contactIsValid:true,
    countryIsSelected:false,
    contactFieldTouched:false,
    contactFieldTouchedOnce:false,
    selectedContactObj:{},
    countryObjBeforeChange:{},
    selectedContactCountry:[],
    //google 2FA
    authenticatorForm:{
      otp:'',
      secretKey:'',
      isOpen:false,
      isLoading:false,
    },
    qrCode:{
      url:''
    },
    //social logins
    socialLoginLoader:false,
    google:{},
    microsoft:{},

    //
    checkedItems:[],
    socialLoginsList:[],
    deletingItems:[],
    deleteLoader:false,

  }),
  components:{
    subHeader,
    tutorialsAction,
    contactInput,
    VueQr,
    OtpInput,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
    })
  },
  watch:{
  },
  methods:{
    handleFormat(form,field){
      
      if(form == 'add' && field == 'f_name'){

        let fname = this.storeAdmin.first_name;
        fname = fname.replace(/[^a-zA-Z ]/g, '');
        fname = fname.replace(/(\W)\W*/g, '$1');

        this.storeAdmin.first_name = fname;

      }else if(form == 'add' && field == 'l_name'){

        let lname = this.storeAdmin.last_name;
        lname = lname.replace(/[^a-zA-Z ]/g, '');
        lname = lname.replace(/(\W)\W*/g, '$1');

        this.storeAdmin.last_name = lname;

      }

    },
    async changeAddImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.adminImage.image = file;
          this.adminImage.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6);
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }
      
      }

    },
    openPasswordForm(){

      this.passwordForm = {
        password: '',
        confirm_password: '',
        current_password: '',
        passwordFieldType:true,
        confirmPasswordFieldType:true,
        currentPasswordFieldType:true,
        id: '',
        isOpen: true,
        isLoading: false
      }

      this.$refs.adminUpdatePasswordObserver.reset();

    },
    closePasswordForm(){

      this.passwordForm = {
        password: '',
        confirm_password: '',
        current_password: '',
        id: '',
        isOpen: false,
        isLoading: false
      }

      this.$refs.adminUpdatePasswordObserver.reset();

    },
    openEmailChangePopup(){

      this.emailChangePopup = {
        isOpen: true,
        new_email:"",
        isLoading:false,
      }

    },
    closeEmailChangePopup(){

      this.emailChangePopup = {
        isOpen: false,
        new_email:"",
        isLoading:false,
      }

      this.$refs.adminUpdateEmailObserver.reset();

    },
    //contact no. methods starts here
    // setMobileNoFieldValidations(validations){

    //   this.contactIsValid = validations.isContactValid
    //   this.countryIsSelected = validations.isCountrySelected
    //   this.contactFieldTouched = validations.isFieldTouched
    //   this.selectedContactObj = validations.contactObj

    //   this.storeAdmin.mobile_no = validations.addContactNo

    // },
    validateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.contactIsValid = contactObj.valid;

        if(!this.contactIsValid){

          this.contactFieldTouched = true;

        }else{

          this.contactFieldTouched = false;

        }

      }

      if(!this.contactFieldTouchedOnce){

        this.storeAdmin.mobile_no = this.tempStoreAdmin.mobile_no

        this.$refs.contactNoField.phone = this.tempStoreAdmin.mobile_no

      }

    },
    contactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.countryIsSelected = false;

      }else if (typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.countryIsSelected = false;

      }else if (typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.countryIsSelected = false;

      }else{

        this.countryIsSelected = true;
        
      }

      this.selectedContactObj = numberObj;

    },
    dropDownOpenHandle(){

      this.countryObjBeforeChange = this.selectedContactObj;

    },
    dropDownCloseHandle(){

      if((this.storeAdmin.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

        if((this.countryObjBeforeChange.country != this.selectedContactObj.country)){

          this.storeAdmin.mobile_no = "+" + this.selectedContactCountry.dialCode;

        }

      }

      this.countryIsSelected = true;

    },
    countryChangeHandle(countryObj){

      this.selectedContactCountry = countryObj;

    },
    contactFieldFocusHandle(){

      this.contactFieldTouched = true;

      this.contactFieldTouchedOnce = true;

    },
    //contact no. methods ends here
    //2fa authenticator methods
    async twoFactorChangeHandle(){

      let formData = new FormData();

      formData.append('isTwoFactorEnabled',this.storeAdmin.two_factor_enabled ? 1 : 0);
      
      let loader = Loading.service({
        text: "Two factor authentication is being enabled. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.post('/store-admin/2fa/change',formData)
        if(res.data.status_code == '4020'){
          
          this.qrCode.url = res.data.url;

          this.authenticatorForm.secretKey = res.data.key;

          this.authenticatorForm.isOpen = true;

        }else if(res.data.status_code == '4031'){
          
          this.$store.state.user.two_factor_enabled = 0;
          this.$store.state.user.google_2fa_secret = null;

          this.$cookies.set("meri_web_shop.current_user", this.$store.state.user);

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

        }
      }catch(error){

        this.closeAuthenticatorForm('failure');

        if(error.response){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

      }

    },
    handleOtpChange(value){

      this.authenticatorForm.otp = value;

    },
    handleOtpComplete(value){

      this.authenticatorForm.otp = value;

      this.authenticationCompleteHandle();

    },
    async authenticationCompleteHandle(){
      
      let formData = new FormData();

      formData.append('otp',this.authenticatorForm.otp);
      formData.append('key',this.authenticatorForm.secretKey);

      let loader = Loading.service({
        text: "Two factor authentication is being configured. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.post('/store-admin/2fa/register',formData);
        if(res.data.status_code == "4021"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.state.user.two_factor_enabled = 1;
          this.$store.state.user.google_2fa_secret = this.authenticatorForm.secretKey;

          this.$cookies.set("meri_web_shop.current_user", this.$store.state.user);

          this.closeAuthenticatorForm('success');

        }
      }catch(error){

        this.closeAuthenticatorForm('failure');

        if(error.response){

          if(error.response.data.error.includes('Failed to setup')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

             this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

      }

    },
    closeAuthenticatorForm(action){

      if(action == 'success'){

        this.authenticatorForm = {
          otp:'',
          secretKey:'',
          isOpen:false,
          isLoading:false,
        }

        this.qrCode = {
          url: ''
        }

        this.storeAdmin = _.cloneDeep(this.$store.state.user);

        this.$refs.otpInput.clearInput();

        this.$refs.userAuthenticate.reset();

      }else if(action == 'failure'){

        this.authenticatorForm = {
          otp:'',
          secretKey:'',
          isOpen:false,
          isLoading:false,
        }

        this.qrCode = {
          url: ''
        }

        this.storeAdmin = _.cloneDeep(this.$store.state.user);

        this.$refs.otpInput.clearInput();

        this.$refs.userAuthenticate.reset();
        
      }else if(action == 'forceClose'){

        this.authenticatorForm.isOpen = false;
        MessageBox.confirm(
          'Are you sure you want to close.? Changes will be reverted',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        ).then(async ()=>{

          this.authenticatorForm = {
            otp:'',
            secretKey:'',
            isOpen:false,
            isLoading:false,
          }

          this.qrCode = {
            url: ''
          }

          this.storeAdmin = _.cloneDeep(this.$store.state.user);

          this.$refs.otpInput.clearInput();

          this.$refs.userAuthenticate.reset();

        }).catch(()=>{

          this.authenticatorForm.isOpen = true;

        })

      }

    },
    //save profile changes
    async saveProfile(){

      if(typeof this.selectedContactObj.valid == 'undefined'){

        this.contactIsValid = false;
        this.contactFieldTouched = true;

      }

      if(this.contactIsValid && this.countryIsSelected){

        let formData = new FormData();

        formData.append('id',this.storeAdmin.id);
        formData.append('first_name',this.storeAdmin.first_name);
        formData.append('last_name',this.storeAdmin.last_name);
        formData.append('mobile_no',this.storeAdmin.mobile_no.replace(/\s/g,''));
        formData.append('address_line_1',this.storeAdmin.address_line_1);
        formData.append('two_factor_enabled', this.storeAdmin.two_factor_enabled == 1 ? 1 : 0);
        
        if(this.storeAdmin.address_line_2){

          formData.append('address_line_2',this.storeAdmin.address_line_2);

        }

        if(this.adminImage.image && this.adminImage.image != ''){

          formData.append('image', this.adminImage.image);

        }

        this.isLoading = true;
        try{
          let res = await this.$axios.post('updateProfile',formData);
          if(res.data.status_code == "1190"){
            
            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            let token = this.$store.state.user.token;

            let updatedAdmin = res.data.admin;

            updatedAdmin.roles = res.data.admin.roles.map((role)=>{
              return role.name;
            });

            updatedAdmin.token = token;

            this.$store.dispatch('authLoggedIn', updatedAdmin);

            this.$cookies.set("meri_web_shop.current_user", updatedAdmin);

            this.$store.commit('loggedIn', updatedAdmin);

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }
        }catch(error){

          if(error.response){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.isLoading = false;

          this.loadProfile();

        }

      }

    },
    async validate(action){

      if(action == "updatePassword"){  

        this.$refs.adminUpdatePasswordObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.adminUpdatePasswordObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.adminUpdatePasswordObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.handleUpdatePassword();

          }

        });

      }else if(action == "updateEmail"){

        this.$refs.adminUpdateEmailObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.adminUpdateEmailObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.adminUpdateEmailObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.handleUpdateEmail();

          }

        });

      }else if(action == "userAuthenticator"){

        this.$refs.userAuthenticate.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.userAuthenticate.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.userAuthenticate.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.authenticateUser();

          }

        });

      }

    },
    async handleUpdatePassword(){

      if(this.passwordForm.password != this.passwordForm.confirm_password){

        this.$notify.error("Oops, password wasn't matched!");
        this.passwordForm.isOpen = false;
        
      }else{

        this.passwordForm.isLoading = true;
        try{

          let formData = new FormData();

          formData.append('current_password',this.passwordForm.current_password);
          formData.append('new_password',this.passwordForm.password);
  
          let res = await this.$axios.post('updatePassword',formData);
          if(res.data.status_code == '1191'){
            
            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            let token = this.$store.state.user.token;
          
            let updatedAdmin = res.data.admin;

            updatedAdmin.token = token;

            this.$store.state.user = updatedAdmin;

            this.$cookies.set("meri_web_shop.current_user", updatedAdmin);
            this.$store.commit('loggedIn', updatedAdmin);

            this.closePasswordForm();
            
          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("Current password") || 
            error.response.data.error.includes("New password")){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{
          
          this.closePasswordForm();

        }

      }

    },
    async handleUpdateEmail(){

      let formData = new FormData();

      formData.append('email_address',this.emailChangePopup.new_email);

      this.emailChangePopup.isLoading = true;
      try{
        let res = await this.$axios.post('/admin/email/change/request',formData);
        if(res.data.status_code == "1145"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes('email address') ||
          error.response.data.error.includes("been verified")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.closeEmailChangePopup();

      }

    },

    //social logins
    async connectSocialPlatform(platform){

      let formData = new FormData();

      if(platform == 'google'){

        formData.append('social_platform',platform);
        formData.append('social_id', this.google.id);
        formData.append('first_name',this.google.given_name);
        formData.append('last_name',this.google.family_name);
        formData.append('email',this.google.email);

      }

      if(platform == 'microsoft'){

        let userFirstName = ''
        let userLastName = ''

        const firstSpaceIndex = this.microsoft.name.indexOf(" ");

        if(firstSpaceIndex != -1){

          const userFirstName = this.microsoft.name.slice(0, firstSpaceIndex);
          const userLastName = this.microsoft.name.slice(firstSpaceIndex + 1);

          formData.append('social_platform',platform);
          formData.append('social_id',this.microsoft.localAccountId);
          formData.append('first_name',userFirstName);
          formData.append('last_name',userLastName);
          formData.append('email',this.microsoft.idTokenClaims.email)

        }else{

          userFirstName = this.microsoft.name;

          formData.append('social_platform',platform);
          formData.append('social_id',this.microsoft.localAccountId);
          formData.append('first_name',userFirstName);
          formData.append('last_name',userLastName);
          formData.append('email',this.microsoft.idTokenClaims.email)

        }

      }
      
      this.socialLoginLoader = true;
      try{

        let res = await this.$axios.post('/social-login/connect',formData);
        if(res.data.status_code == "1410"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let loggedInUser = this.$store.state.user;

          loggedInUser.social_logins.push(res.data.social_login);

          // this.$store.dispatch('authLoggedIn', loggedInUser);

          this.$cookies.set("meri_web_shop.current_user", loggedInUser);

        }

      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("social account")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.socialLoginLoader = false;

        this.loadProfile();

      }

    },

    //google
    async googleConnect(){

      let initgoogle = google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID_FINAL,
        callback: this.handleCredentialResponse,
        auto_select: false,
        scope: "profile email"
      });

      initgoogle.requestAccessToken();

    },
    async handleCredentialResponse(response){

      await fetch("https://www.googleapis.com/userinfo/v2/me",{
        method: "GET",
        headers: {
          "Authorization": `Bearer ${response.access_token}`
        },
      }).then((userInfo) => {

        return userInfo.json();

      }).then((data)=>{

        this.google = data;

        this.connectSocialPlatform('google');

      }).catch((error) => { });

    },

    //microsoft
    async microsoftConnectInitiate(){

      const redurectUrl = window.location.origin + '/settings/profile'

      const msalConfig = {
        auth: {
          clientId: process.env.VUE_APP_OUTLOOK_CLIENT_ID,
          authority: 'https://login.microsoftonline.com/common',
          redirectUri: redurectUrl,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      };

      this.microsoftConnectProceed(msalConfig);

    },
    async microsoftConnectProceed(msalConfig){

      this.socialLoginLoader = true

      const loginRequest = {
        scopes: ['email','openid', 'profile', 'User.Read'],
        prompt: 'select_account',
      };

      const myMSALObj = new msal.PublicClientApplication(msalConfig);

      myMSALObj.loginPopup(loginRequest).then(response => {

        this.microsoft = response.account;

        this.connectSocialPlatform('microsoft');

      }).catch(error => {

        this.socialLoginLoader = false;

      });

    },

    //login logs
    deleteSocialLoginHandle(socialLogin,index){

      MessageBox.confirm(
        'Are you sure you want to disconnect the social account?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async ()=>{

        this.deletingItems.push(socialLogin.id);
        this.deleteLoader = true;

        let loader = Loading.service({
          text: "The connection to the social login is being revoked. Please wait!",
          fullscreen: true,
        });

        try{
          let res = await this.$axios.delete(`social-login/disconnect/${socialLogin.id}`)
          if(res.data.status_code == "1411"){

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            this.deleteLoader = false;
            this.deletingItems = [];

            let loggedInUser = this.$store.state.user;

            // this.$store.dispatch('authLoggedIn', loggedInUser);

            this.$cookies.set("meri_web_shop.current_user", loggedInUser);

          }
        }catch(error){

          this.deleteLoader = false;
          this.deletingItems = [];

          if(error.response){
          
            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

          this.loadProfile();

        }

      }).catch(() => {})

    },

    //profile
    async loadProfile(){

      let loader = Loading.service({
        text: "Fetching data associated with the user. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.get('/profile/me');
        if(res.data.status_code == "5000"){

          this.storeAdmin = _.cloneDeep(res.data.admin);

          this.tempStoreAdmin = _.cloneDeep(res.data.admin);

          if(this.settings.admin){

            this.settings.admin.first_name = res.data.admin.first_name
            this.settings.admin.last_name = res.data.admin.last_name
            this.settings.admin.image = res.data.admin.image

          }

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        loader.close();

      }

    },

    //other
    toggleIsCheckedAll(){

      if((this.checkedItems.length == this.storeAdmin.social_logins.length)){

        this.checkedItems = [];

      }else if(this.checkedItems.length < this.storeAdmin.social_logins.length){

        this.checkedItems = [];
        
        this.storeAdmin.social_logins.forEach(loginLog => {

          if(loginLog.id != -1){

            this.checkedItems.push(loginLog.id);

          }

        });

      }

    },
    clearSelection(){

      this.checkedItems = [];

    },
    checkedItemHandle(socialLoginId){

      let index = this.checkedItems.findIndex(item => item == socialLoginId);

      if(index != -1){

        this.checkedItems.splice(index,1);

      }else{

        this.checkedItems.push(socialLoginId);

      }

    },
    isDeletingItem(id){

      let find = this.deletingItems.find(x=>x == id);

      if(find){

          return true;

      }else{

          return false;

      }

    },

    //environment check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true;

      }else{

        return false;

      }

    }
  },
  mounted(){

  },
  async beforeMount(){

    this.loadProfile();

  },

}
</script>
  
<style>
  .formContentBox .changePasswordEyeBtn{
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    border: none;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 44px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .formContentBox.otp .text-danger{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
</style>